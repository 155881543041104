<div class="header-wrapper" [class.expanded]="expanded">

  <div class="message-section" #messageContainer>
    <ng-content select="[HeaderMessage]"></ng-content>
  </div>

  <div class="header-title">
    @if (pageTitle() === "") { LOGILAB } @else {
    {{ pageTitle() }}
    }
  </div>

  @if (!headerOnly) {
  <div class="header-container">
    <div class="header-content">
      <div class="header-left">
        <div class="header-actions">
          <a class="d-flex align-items-center button-basic-info mx-2" background="none" (click)="onNavigateAction()">
            <app-icon size="xl" name="left-long" package="solid"></app-icon>
          </a>
        </div>
        <div class="separator-vr"></div>
      </div>

      <div class="header-right mx-1">
        <div class="header-template-left">
          <ng-content select="[HeaderLeft]"></ng-content>
        </div>
        <div class="header-template-right">
          <ng-content select="[HeaderRight]"></ng-content>
        </div>
        <ng-container *ngIf="ExpandedBody.childNodes.length != 0">
          <div class="d-flex align-items-center h-100">
            <div class="separator-vr"></div>
            <div class="button-basic-info ms-1 d-flex align-items-center" background="none" (click)="toggleExpand()">
              <app-icon size="lg" [name]="expanded ? 'chevrons-up' : 'chevrons-down'" package="regular"></app-icon>
            </div>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="extra-content" #ExpandedBody [class.hidden]="!expanded">
      <ng-content select="[HeaderBody]"></ng-content>
    </div>
  </div>
  }
</div>
